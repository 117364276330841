// Food Items array
const foodItems = [
  {
    label: 'starters',
    description: '',
    url: '/menu/alacarte/starters',
    imgSrc: '/assets/img/foods/starters.jpg',
    menuItems: [
      { name: 'Chicken Gyoza', description: '', price: 6.50, ingredients: '5pc, cabbage, chicken, garlic, gyoza sauce' },

      { name: 'Yasai Gyoza', description: '', price: 5.50, ingredients: '5pc, cabbage, soya, garlic, gyoza sauce' },

      { name: 'Duck Gyoza', description: '', price: 7.50, ingredients: '5pc, cabbage, duck, garlic, gyoza sauce' },

      { name: 'Prawn Tempura', description: '', price: 9.20, ingredients: '5 pc, deep fried prawn, dashi soy sauce, grated daikon, ginger' },

      { name: 'Yasai Veg Tempura', description: '', price: 6.10, ingredients: '5 pc, mix vegetables, dashi soy sauce, grated daikon, ginger' },

      { name: 'Salmon Tempura', description: '', price: 8.50, ingredients: '5 pc, deep fried fresh salmon, soy sauce, grated daikon, ginger' },

      { name: 'Edamame', description: '(GF)(VG)', price: 5.20, ingredients: 'Japanese soybeans, rock salt' },

      { name: 'Ebi Katsu', description: '', price: 6.80, ingredients: '3 pc, deep fried king prawn, spicy truffle mayo, spring onion' },

      { name: 'Miso Soup', description: '(GF)', price: 3.50, ingredients: 'tofu, spring onions, seaweed' },

      { name: 'Chicken Karaage', description: '', price: 6.50, ingredients: '5 pc, marinated chicken in garlic and ginger, deep fried served with lime and spicy truffle mayo' },

      { name: 'Takoyaki', description: '', price: 9.00, ingredients: '5 pc, deep fried octopus balls, mayo, tonkatsu sauce, bonito flakes, seaweed flakes' },

    ]
  },
  {
    label: 'sushi salad',
    description: '',
    url: '/menu/alacarte/sushisalad',
    imgSrc: '/assets/img/foods/pokebowls.jpg',
    menuItems: [
      { name: 'Wakame Salad', description: '(V)', price: 6.50, ingredients: '60g wakame salad' },
    ]
  },
  {
    label: 'poke bowls',
    description: '',
    url: '/menu/alacarte/pokebowls',
    imgSrc: '/assets/img/foods/pokebowls.jpg',
    menuItems: [
      { name: 'Salmon Poke Bowl', description: '', price: 12.50, ingredients: 'fresh diced salmon, avocado, pickled cucumber, mixed salad, mooli, onion, sushi rice, homemade sesame dressing' },

      { name: 'Tuna Poke Bowl', description: '', price: 14.50, ingredients: 'fresh diced tuna, avocado, carrots, pickled cucumber, mixed salad, mooli, onion, sushi rice, homemade sesame dressing' },

      { name: 'Seaweed Poke Bowl', description: '', price: 9.50, ingredients: 'kaiso salad, tofu, pickled cucumber, mixed salad, mooli, onion, sushi rice, homemade sesame dressing' },

    ]
  },
  {
    label: 'rice dishes',
    description: '',
    url: '/menu/alacarte/ricedishes',
    imgSrc: '/assets/img/foods/ricedishes.jpg',
    menuItems: [
      { name: 'Chicken Katsu Curry', description: '', price: 10.50, ingredients: 'plain steamed rice, japanese curry sauce, dry onion, sesame seeds, micro herbs, chicken katsu' },

      { name: 'Prawn Katsu Curry', description: '', price: 11.50, ingredients: 'plain steamed rice, japanese curry sauce, dry onion, sesame seeds, micro herbs, prawn katsu' },

      { name: 'Veg Katsu Curry', description: '', price: 10.50, ingredients: 'plain steamed rice, japanese curry sauce, dry onion, sesame seeds, micro herbs, vegetable katsu' },

      { name: 'Salmon Teriyaki', description: '', price: 13.50, ingredients: 'fried salmon, steamed broccoli, plain steamed rice, teriyaki sauce' },

    ]
  },
  {
    label: 'sashimi',
    description: '5 pcs of sashimi served raw',
    url: '/menu/alacarte/sashimi',
    imgSrc: '/assets/img/foods/sashimi.jpg',
    menuItems: [
      { name: 'Salmon Sashimi', description: '(GF)', price: 11.00, ingredients: '' },

      { name: 'Tuna Sashimi', description: '(GF)', price: 12.80, ingredients: '' },

      { name: 'Sea Bass Sashimi', description: '(GF)', price: 11.50, ingredients: '' },

      { name: 'Sea Bream Sashimi', description: '(GF)', price: 11.50, ingredients: '' },

      { name: 'Yellowtail Sashimi', description: '(GF)', price: 18.00, ingredients: '' },

      { name: 'Grouper Sashimi', description: '(GF)', price: 18.00, ingredients: '' },

      { name: 'Scallops Sashimi', description: '(GF)', price: 13.50, ingredients: '' },

      { name: 'Mix Sashimi', description: '(GF)', price: 40.00, ingredients: '20pcs' },

    ]
  },
  {
    label: 'nigiri',
    description: 'slices of toppings on a pillow of rice',
    url: '/menu/alacarte/nigiri',
    imgSrc: '/assets/img/foods/nigiri.jpg',
    menuItems: [
      { name: 'Salmon Nigiri', description: '(GF)', price: 4.60, ingredients: '' },

      { name: 'Tuna Nigiri', description: '(GF)', price: 5.60, ingredients: '' },

      { name: 'Sea Bass Nigiri', description: '(GF)', price: 5.60, ingredients: '' },

      { name: 'Cooked Prawn Nigiri', description: '', price: 4.60, ingredients: '' },

      { name: 'Inari Nigiri', description: '(V)', price: 4.90, ingredients: 'sweet tofu skin' },

      { name: 'Avocado Mayo Nigiri', description: '(V)', price: 3.90, ingredients: '' },

    ]
  },
  {
    label: 'temaki',
    description: 'a traditional Japanese hand roll w/ rice & fillings',
    url: '/menu/alacarte/temaki',
    imgSrc: '/assets/img/foods/temaki.jpg',
    menuItems: [
      { name: 'Salmon Temaki', description: '', price: 6.90, ingredients: 'seaweed cone stuffed with fresh salmon' },

      { name: 'Tuna Temaki', description: '', price: 7.90, ingredients: 'seaweed cone stuffed w/ fresh tuna, avocado' },

      { name: 'Prawn Katsu Temaki', description: '', price: 6.90, ingredients: 'seaweed cone stuffed w/ crispy prawns, avocado, cream cheese, spicy truffle mayo' },

      { name: 'Salmon Philadelphia Temaki', description: '(GF)', price: 6.90, ingredients: 'seaweed cone stuffed w/ fresh salmon, cream cheese' },

      { name: 'Softshell Crab Tempura Temaki', description: '', price: 7.90, ingredients: 'seaweed cone stuffed w/ softshell crab tempura, mixed salad, spicy truffle mayo' },

      { name: 'Prawn Tempura Temaki', description: '', price: 5.90, ingredients: 'prawn tempura, avocado, mixed salad, sesame seeds' },

      { name: 'Yasai Temaki', description: '(GF)', price: 5.90, ingredients: 'inari, avocado, mango, cucumber, mixed salad' },
      
    ]
  },
  {
    label: 'hosomaki',
    description: 'a variety of fillings & thin rolls of rice w/ seaweed on the outside, 8 PC',
    url: '/menu/alacarte/hosomaki',
    imgSrc: '/assets/img/foods/hosomaki.jpg',
    menuItems: [
      { name: 'Tuna Hosomaki', description: '(GF) (PLAIN OR SPICY)', price: 7.00, ingredients: '' },

      { name: 'Salmon Hosomaki', description: '(GF) (PLAIN OR SPICY)', price: 6.90, ingredients: '' },

      { name: 'Prawn Hosomaki', description: '(GF)', price: 6.70, ingredients: '' },

      { name: 'Sea Bass Hosomaki', description: '(GF) (PLAIN OR SPICY)', price: 6.50, ingredients: '' },

      { name: 'Cucumber Hosomaki', description: '(VG, GF)', price: 4.50, ingredients: '' },

      { name: 'Avocado Hosomaki', description: '(VG, GF)', price: 4.80, ingredients: '' },

    ]
  },
  {
    label: 'hot sushi roll',
    description: '6 pc of hot sushi roll',
    url: '/menu/alacarte/hotsushiroll',
    imgSrc: '/assets/img/foods/hotsushiroll.jpg',
    menuItems: [
      { name: 'Hot Philadelphia Roll', description: '', price: 8.60, ingredients: 'Salmon, avocado, tobiko, philadelphia cream cheese, mayo, & teriyaki sauce.' },

      { name: 'Hot Tiger Roll', description: '', price: 8.60, ingredients: 'Tempura roll with salmon, prawn nigiri, cream cheese, truffle mayo, & teriyaki sauce.' },

      { name: 'Volcano Sushi', description: '', price: 9.50, ingredients: 'Tempura salmon topped with salmon tartare, massago, salmon tartare, & spring onion.' },

      { name: 'Tuna Maki Tempura', description: '', price: 7.00, ingredients: 'Tempura tuna, truffle mayo, & chilli powder.' },

      { name: 'Salmon Maki Tempura', description: '', price: 6.80, ingredients: 'Tempura salmon, truffle mayo, & chilli powder.' },

    ]
  },
  {
    label: 'iso uramaki roll',
    description: 'a layer of rice on the outside and a sheet of nori on the inside & fresh variety of filings, 8 pcs',
    url: '/menu/alacarte/isouramakiroll',
    imgSrc: '/assets/img/foods/iso-uramaki.jpg',
    menuItems: [
      { name: 'California Uramaki', description: '(GF)', price: 8.60, ingredients: 'crab meat, avocado, cucumber, sesame seeds, tobiko orange' },
      { name: 'Ebi Katsu Uramaki', description: '', price: 9.80, ingredients: 'prawn katsu, avocado, cream cheese, orange masago, teriyaki sauce' },
      { name: 'Alaskan Uramaki', description: '(GF)', price: 9.50, ingredients: 'fresh salmon, avocado, cream cheese, sesame seeds' },
      { name: 'Salmon Philadelphia Uramaki', description: '', price: 8.60, ingredients: 'fresh salmon, masago, sesame seeds' },
      { name: 'Spicy Tuna Cucumber Uramaki', description: '', price: 9.00, ingredients: 'tuna marinated w/ sriracha chilli, cucumber, masago, spicy mayo' },
      { name: 'Tuna Avocado Uramaki', description: '', price: 9.00, ingredients: 'fresh tuna, avocado, sesame seeds, tobiko orange' },
      { name: 'Salmon Avocado Uramaki', description: '', price: 8.50, ingredients: 'fresh salmon, avocado, sesame seeds, tobiko orange' },
      { name: 'Tsukiji Lobster Uramaki', description: '', price: 14.50, ingredients: 'fresh lobster, avocado, sesame seeds' },
      { name: 'Vegan Uramaki', description: '(V)', price: 7.50, ingredients: 'cucumber, mango, marinated pumpkin, sesame seeds' },
    ]
  },
  {
    label: 'tsukiji special',
    description: 'tsukiji\'s special dragon rolls, 10 pcs',
    url: '/menu/alacarte/tsukijispecial',
    imgSrc: '/assets/img/foods/tsukiji-special.jpg',
    menuItems: [
      { name: 'Green Dragon Roll', description: '', price: 13.50, ingredients: 'Prawn tempura topped with sliced avocado, japanese mayo, massago, and teriyaki sauce.' },

      { name: 'Spicy Tuna Dragon Roll', description: '', price: 14.90, ingredients: 'Tuna and cucumber topped with sliced tuna. Garnished with spicy kimchi, sesame sauce, sriracha sauce, and tempura super crunch.' },

      { name: 'Spicy Salmon Dragon Roll', description: '', price: 14.60, ingredients: 'Salmon and cucumber topped with sliced salmon. Garnished with kimchi sauce, sesame seeds, sriracha sauce, and tempura super crunch.' },

      { name: 'Chicken Katsu Dragon Roll', description: '', price: 14.60, ingredients: 'Chicken katsu and cucumber topped with soft avocado and spicy truffle mayo.' },

      { name: 'Spicy Salmon Lava Roll', description: '', price: 13.60, ingredients: 'Salmon marinated with sriracha sauce, spring onions, cucumber. Topped with tartare salmon. Garnished with tempura super crunch.' },

      { name: 'Spider Salmon Dragon Roll', description: '', price: 15.60, ingredients: 'Softshell crab tempura and cucumber topped with torched sliced salmon & spicy truffle mayo.' },

      { name: 'Tsukiji Dragon Roll', description: '', price: 14.50, ingredients: 'Prawn katsu tempura topped with salmon, torched spicy mayo, teriyaki sauce, and crispy onion.' },

      { name: 'Tsukiji Lobster Dragon Roll', description: '', price: 22.50, ingredients: 'Lobster tempura & cucumber topped with avocado, black tobiko, mayo, and teriyaki sauce.' },

      { name: 'Lobster Tail Dragon Roll', description: '', price: 22.00, ingredients: 'Lobster tempura & cucumber topped with avocado, black tobiko, mayo, and teriyaki sauce.' },

      { name: 'Tsukiji Scallop Dragon Roll', description: '', price: 16.50, ingredients: 'Scallop tempura & avocado topped with torchedyellowtail marinated in olive oil, orange tobiko, and teriyaki sauce.' },

    ]
  },
  {
    label: 'sushi platter',
    description: 'platters to share',
    url: '/menu/alacarte/sushiplatter',
    imgSrc: '/assets/img/foods/sushi-platter.jpg',
    menuItems: [
      { name: 'Salmon Lover', description: '', price: 22.50, ingredients: '15 total pcs, tempura maki (6pcs), salmon nigiri (3pcs), salmon sashimi (6pcs)' },

      { name: 'Tuna Lover', description: '', price: 24.50, ingredients: '15 total pcs, tempura maki & tuna temaki avocado (6pcs), tuna nigiri (3pcs), tuna sashimi (6pcs)' },

      { name: 'Tsukiji Maki Set', description: '', price: 34.00, ingredients: '30 total pcs, green dragon rolls, salmon tempura maki, california uramaki, salmon nigiri' },

      { name: 'Tokyo F/Style Platter', description: '', price: 25.50, ingredients: '16 total pcs, gunkan special (2pcs), nigiri (3pcs), special dragin rolls (6pcs), assorted sashimi (5pcs)' },

      { name: 'Tsukiji F/Style Platter', description: '', price: 48.00, ingredients: '40 total pcs, gunkan special (4pcs), nigiri (4pcs), tempura maki (8pcs), special dragon rolls (8pcs), mixed sashimi (12pcs)' },

      { name: 'Osaka F/Style Platter', description: '', price: 95.00, ingredients: '70 total pcs, gunkan special (5pcs), nigiri (5pcs), tempura maki (8pcs), special rolls (30pcs), mixed sashimi (15pcs)' },

      { name: 'Veg Freestyle Platter', description: '', price: 25.00, ingredients: '16 total pcs, special dragon rolls (6pcs), avocado tempura (6pcs), gunkan (2pcs), nigiri (2pcs)' },

    ]
  },
  {
    label: 'ceviche',
    description: '160 grams per serving',
    url: '/menu/alacarte/ceviche',
    imgSrc: '/assets/img/foods/ceviche.jpg',
    menuItems: [
      { name: 'Salmon Ceviche', description: '', price: 10.80, ingredients: 'raw salmon, lemon juice, soy sauce, kimchi sauce, ponzu sauce, red & yellow pepper, fresh chillies, spring onion' },
      { name: 'Tuna Ceviche', description: '', price: 12.80, ingredients: 'raw tuna, lemon juice, soy sauce, kimchi sauce, ponzu sauce, red & yellow pepper, fresh chillies, spring onion' },
      { name: 'Sea Bass Ceviche', description: '', price: 14.80, ingredients: 'raw sea bass, lemon juice, soy sauce, kimchi sauce, ponzu sauce, red & yellow pepper, fresh chillies, spring onion' },
    ]
  },
]



export default foodItems